<template>
  <VueSlickCarousel
    class="carousel--wrapper"
    v-bind="settings"
    @beforeChange="beforeChange"
    @afterChange="afterChange"
  >
    <div v-for="(banner, index) in banners" :key="`banner-${index}`" class="carousel--promo-slide">
      <router-link
        :to="banner.links ? banner.links.detail : ''"
        class="carousel--promo-link"
        @click="clickHandler"
      >
        <banner-single :banner="banner"></banner-single>
      </router-link>
      <transition>
        <div class="home--banner-desc" v-if="banner" :key="`banner-desc-${banner.id}`">
          <div class="top--text mb-2 multiline-ellipsis-4">
            {{ banner.listing_title }}
          </div>
          <!--              <div class="top--text mb-2">-->
          <!--                {{ banner.user.name }}-->
          <!--              </div>-->
          <div class="bottom--text btn-primary" v-if="banner.user">
            {{ banner.user.phone }}
          </div>
        </div>
      </transition>
    </div>
    <template #prevArrow>
      <div class="custom-prev--arrow"></div>
    </template>
    <template #nextArrow>
      <div class="custom-next--arrow"></div>
    </template>
  </VueSlickCarousel>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
const BannerSingle = () => import('@/components/home/partials/banner-single');
export default {
  mixins: [HelperMixin],
  components: {
    BannerSingle,
  },
  props: {
    banners: {
      default: [],
      require: true,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      centerMode: true,
      dots: false,
      edgeFriction: 0.35,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesPerRow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      // lazyLoad: 'progressive', // Ini kalo barengan multiple rows jadi gak bisa slide
      draggable: false,
    },
  }),
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
